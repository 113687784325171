/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Props, type PartialProps } from "../../system/HewSync";
import { UserOrganizationID } from "../../system/IDs";


@HewSync.Type({
  "name": "UserOrganization",
  "scope": "User",
  "table": "user",
  "type": "User:Organization"
})
export class UserOrganization extends HewSyncType {
	public static readonly Type = UserOrganization as typeof HewSyncType;
	public static override readonly type = "UserOrganization";
	public override readonly type = UserOrganization.type;

	@HewSync.Field({ })
	public readonly organization: UserOrganizationID;

	@HewSync.Field({ })
	public readonly config: any;

	@HewSync.Field({ })
	public readonly description: string;

	@HewSync.Field({ })
	public readonly name: string;

	@HewSync.Field({ })
	public readonly settings: any;

	@HewSync.Field({ })
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly updatedAt: HewSync.Timestamp;


	/********************************************/

	public static get(ids: Pick<UserOrganization, UserOrganization.IDs>) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new UserOrganization({
				...UserOrganization.defaults(),
				organization: ids.organization,
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: Pick<UserOrganization, UserOrganization.IDs>) {
		return HewSync.get<UserOrganization>(UserOrganization.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<Pick<UserOrganization, UserOrganization.IDs>>) {
		let list = new HewSyncList<UserOrganization>(UserOrganization.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<Pick<UserOrganization, UserOrganization.IDs>> & { limit?: number; nextToken?: string; }) {
		return HewSync.list<UserOrganization>(UserOrganization.Type, inputs);
	}

	public static batchGet(ids: Pick<UserOrganization, UserOrganization.IDs>[]) {
		return HewSync.batchGet<UserOrganization>(UserOrganization.Type, ids);
	}

	public static create(values: UserOrganization.Create) {
		return HewSync.create<UserOrganization>(UserOrganization.Type, "create", values);
	}

	public static update(ids: Pick<UserOrganization, UserOrganization.IDs>, values: Partial<Pick<UserOrganization, UserOrganization.Modifiable>>, target?: UserOrganization) {
		return HewSync.request<UserOrganization>(UserOrganization.Type, "update", {...values, ...ids}, target);
	}

	public static remove(ids: Pick<UserOrganization, UserOrganization.IDs>, target?: UserOrganization) {
		return HewSync.request<UserOrganization>(UserOrganization.Type, "remove", ids, target);
	}

	/********************************************/

	public update(values: Partial<Pick<UserOrganization, UserOrganization.Modifiable>>) {
		return UserOrganization.update(this.ids, values, this);
	}

	public remove() {
		return UserOrganization.remove(this.ids, this);
	}

	/********************************************/

	private static cache = new Map<string, UserOrganization>();

	public get ids() { return { organization: this.organization }; }

	public static getKey(ids: Pick<UserOrganization, UserOrganization.IDs>) { return ids.organization.toString(); }

	public get key() { return this.organization.toString(); }

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).organization = data.organization;

		this.apply({ ...UserOrganization.defaults(), ...data });
	}

	protected override apply(data: Partial<Pick<UserOrganization, UserOrganization.Fields>>) {
		(this as any).config = data.config;
		(this as any).description = data.description;
		(this as any).name = data.name;
		(this as any).settings = data.settings;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as UserOrganization;
		(values as any).config = {};
		(values as any).description = "";
		(values as any).name = "";
		(values as any).settings = {};
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): PartialProps<UserOrganization, UserOrganization.IDs | UserOrganization.Fields> {
		let output: PartialProps<UserOrganization, UserOrganization.IDs | UserOrganization.Fields> = {};
		if (data.organization !== undefined) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.config !== undefined) {
			output.config = data.config;
		}
		if (data.description !== undefined) {
			output.description = data.description;
		}
		if (data.name !== undefined) {
			output.name = data.name;
		}
		if (data.settings !== undefined) {
			output.settings = data.settings;
		}
		if (data.createdAt !== undefined) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			organization: this.organization,
			config: this.config,
			description: this.description,
			name: this.name,
			settings: this.settings,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["organization"] as const;
	public readonly fieldIDs = UserOrganization.fieldIDs;
	public static readonly fields = ["config", "description", "name", "settings", "createdAt", "updatedAt"] as const;
	public readonly fields = UserOrganization.fields;
}

export namespace UserOrganization {
	export type IDs = "organization";
	export type Fields = "config" | "description" | "name" | "settings" | "createdAt" | "updatedAt";
	export type Modifiable = "config" | "description" | "name" | "settings";
	export type Create = {
		config?: any;
		description?: string;
		name: string;
		settings?: any;
	}

}
